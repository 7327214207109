import { getBrowser } from '../../../core/utils'
import { APP_TYPE } from '../constants/application-types.constants'

export const assembleNormalizedHome = (selectedHome, state) => {
  return {
    ...selectedHome,
    ...state.addressForm.cp_municipio,
    ...state.addressForm.numero,
    nombre_via: state.addressForm.direccion.nombreVia,
    tipo_calle: state.addressForm.direccion.tipoVia,
  }
}

export const assembleNotNormalizedHome = (state, dataUpdate) => {
  const numero = state.addressForm.numero || { numero: '' }
  const direccion = state.addressForm.direccion || {}
  return {
    ...state.addressForm.cp_municipio,
    numero: numero.numero + (numero.anexo ? ' ' + numero.anexo : ''),
    nombre_via: direccion.nombreVia || '',
    tipo_calle: direccion.tipoVia || '',
    bloque: direccion.bloque || '',
    escalera: direccion.escalera || '',
    piso: direccion.piso || '',
    puerta: direccion.puerta || '',
    ...dataUpdate,
  }
}

export const assembleTmpAppFromState = (state) => {
  let payload = {
    ip: '',
    navegador: getBrowser(),
    nombre: state.customerForm.nombre,
    apellidos_cliente: state.customerForm.apellidos,
    email_cliente: state.customerForm.email,
    identificador: state.customerForm.identificador,
    tipo_identificador: state.customerForm.tipo_identificador,
    telefono1: state.customerForm.telefono,
    tipo_solicitud: 'instalador',
    pasar_contratable: state.customerForm['pasar_contratable'],
    cidi: state.selectedHome['cidi']?.toString(),
    tipo_vivienda: state.selectedHome['tipo_vivienda'],
    cod_postal: state.selectedHome['cod_postal'],
    municipio: state.selectedHome['municipio'],
    tipo_calle: state.selectedHome['tipo_calle'],
    direccion: state.selectedHome['nombre_via'],
    anexo: state.selectedHome['anexo'],
    numero: state.selectedHome['numero'],
    bloque: state.selectedHome['bloque'],
    escalera: state.selectedHome['escalera'],
    piso: state.selectedHome['piso'],
    puerta: state.selectedHome['puerta'],
  }
  if (state.certificateForm?.services?.length) {
    state.certificateForm.services.forEach((serviceKey) => {
      payload[serviceKey] = true
    })
  }
  if (state.customerForm?.services?.length) {
    state.customerForm.services.forEach((serviceKey) => {
      payload[serviceKey] = true
    })
  }
  return payload
}

export const assembleCustomerFromState = (state) => ({
  email: state.customerForm.email,
  nombre: state.customerForm.nombre,
  apellidos: state.customerForm.apellidos,
  telefono: state.customerForm.telefono,
  identificador: state.customerForm.identificador,
  tipo_identificador: state.customerForm.tipo_identificador,
  persona: state.customerForm.persona,
})
export const assembleAppFromStateUnlockPath = (state, installerId) => ({
  instalador_id: installerId,
  empresa_ca_id: state.empresa?.empresa_ca_id,
})
export const assembleCommonPayloadFromTmpApp = (
  subtype,
  state,
  tmpApplication,
  id_client,
  id_installer
) => ({
  subtipo_solicitud: subtype,
  usuario_id: id_client,
  tipo_solicitud: 'instalador',
  iban: state.certificateForm.iban ? state.certificateForm.iban : state.customerForm.iban,
  titular_iban: state.certificateForm.titular_iban
    ? state.certificateForm.titular_iban
    : state.customerForm.titular_iban,
  instalador_id: id_installer,
  instalador_nedgia: true,
  instalacion_exterior: state.selectedHome['dispone_gas'] !== 'GAS_NATURAL',
  solicitudTmp_id: tmpApplication['id'],
  solicitud_id: tmpApplication['solicitud_id'],
  anexo: tmpApplication['selectedHome'],
  bloque: tmpApplication['selectedHome'],
  cidi: tmpApplication['cidi'],
  cod_postal: tmpApplication['cod_postal'],
  direccion: tmpApplication['direccion'],
  escalera: tmpApplication['escalera'],
  municipio: tmpApplication['municipio'],
  numero: tmpApplication['numero'],
  piso: tmpApplication['piso'],
  puerta: tmpApplication['puerta'],
  tipo_calle: tmpApplication['tipo_calle'],
  tipo_vivienda: tmpApplication['tipo_vivienda'],
  persona: tmpApplication['persona'],
  observaciones: state.customerForm['observaciones'],
  empresa_ca_id: state.empresa.empresa_ca_id,
  uso: state.customerForm['uso'],
  potencia: state.customerForm['potencia'],
  consumo: state.customerForm['consumo'],
  numero_tarifa_aplicada: state.customerForm['numero_tarifa_aplicada'],
  latitud: state.customerForm['latitude'],
  longitud: state.customerForm['longitude'],
  sv_latitud: state.customerForm['sv_latitud'],
  sv_longitud: state.customerForm['sv_longitud'],
  sv_height: state.customerForm['sv_height'],
  sv_width: state.customerForm['sv_width'],
  sv_marker_x: state.customerForm['sv_marker_x'],
  sv_marker_y: state.customerForm['sv_marker_y'],
  heading: state.customerForm['heading'],
  sin_ubicacion: state.customerForm['sin_ubicacion'],
  pitch: state.customerForm['pitch'],
  povLat: state.customerForm['povLat'],
  povLng: state.customerForm['povLng'],
  referencia_catastral: state.customerForm['referencia_catastral'],
  camp1: state.certificateForm['camp1']
    ? state.certificateForm['camp1']
    : state.customerForm['camp1'],
  gas_p: state.customerForm['gas_p'] ? state.customerForm['gas_p'] : null,
})

export const assemblePayloadFromTmpApp = (
  subtype,
  state,
  tmpApplication,
  id_user,
  id_installer
) => {
  const common = assembleCommonPayloadFromTmpApp(
    subtype,
    state,
    tmpApplication,
    id_user,
    id_installer
  )

  switch (subtype) {
    case APP_TYPE.SAG:
      return {
        ...common,
        num_suministros_finca_interesados: state.customerForm['num_suministros_finca_interesados'],
        num_suministros_finca_potenciales: state.customerForm['num_suministros_finca_potenciales'],
      }
    case APP_TYPE.SCR:
      return {
        ...common,
        //num_cedula_habitabilidad: state.customerForm['num_cedula_habitabilidad'],
        prop_contador: state.customerForm['prop_contador'],
        caso_caldera_centr: state.customerForm['caso_caldera_centr'],
        ubi_contador: state.customerForm['ubi_contador'],
        prop_irc_armario_reg: state.customerForm['prop_irc_armario_reg'],
        numero_tarifa_aplicada: state.customerForm['numero_tarifa_aplicada'],
      }
    case APP_TYPE.NN:
      return {
        ...common,
        num_suministros_finca_interesados: state.customerForm['num_suministros_finca_interesados'],
        num_suministros_finca_potenciales: state.customerForm['num_suministros_finca_potenciales'],
      }
    case APP_TYPE.NNE:
      return {
        ...common,
        subtipo_solicitud: 'pdte_normalizar_direccion',
        camino_solicitud: '01',
        num_suministros_finca_interesados: state.customerForm['num_suministros_finca_interesados'],
        num_suministros_finca_potenciales: state.customerForm['num_suministros_finca_potenciales'],
      }
    case APP_TYPE.NNI:
      return {
        ...common,
        subtipo_solicitud: 'pdte_normalizar_direccion',
        camino_solicitud: '03',
        num_suministros_finca_interesados: state.customerForm['num_suministros_finca_interesados'],
        num_suministros_finca_potenciales: state.customerForm['num_suministros_finca_potenciales'],
      }
    default:
      throw new Error('Unrecognized application type')
  }
}

export const assembleDocumentPayload = (file, form, id_client, id_app, id_budget) => {
  const payload = {
    tipo: file.type,
    documento: form[file.field].data.split(',')[1],
    nombre: form[file.field].name.replace(/\.[^/.]+$/, ''),
    extension: form[file.field].type,
    cliente_id: parseInt(id_client),
    solicitud_id: id_app.toString(),
  }
  if (id_budget) {
    payload.presupuesto_id = id_budget.toString()
  }
  return payload
}

//PENDING:
//- solicitud_id
//- client_id
export const assembleCertificate = ({
  customerForm,
  selectedHome,
  consultData,
  empresa,
  client_id,
}) => {
  return {
    fecha_certificado: new Date(),
    gas_natural: true,
    //Data from application
    tipo_mercado: consultData.mercado,
    iban: customerForm.iban,
    cups: consultData.cups,
    nombre_titular: customerForm.nombre + ' ' + customerForm.apellidos,
    tipo_documento_titular: customerForm.tipo_identificador,
    persona: customerForm.persona,
    numero_documento_titular: customerForm.identificador,
    telefono_movil_visita: customerForm.telefono,
    email_visita: customerForm.email,
    provincia_emplazamiento: selectedHome.provincia,
    municipio_emplazamiento: selectedHome.municipio,
    via_emplazamiento: selectedHome.tipo_calle + ' ' + selectedHome.nombre_via,
    numero_emplazamiento: selectedHome.numero,
    portal_emplazamiento: selectedHome.bloque,
    escalera_emplazamiento: selectedHome.escalera,
    piso_emplazamiento: selectedHome.piso,
    puerta_emplazamiento: selectedHome.puerta,
    cidi: selectedHome['cidi']?.toString(),
    //Data from installator
    empresa: empresa.nombre,
    empresa_ca_id: empresa.empresa_ca_id,
    numero_registro: '--',
    telefono_movil_empresa: empresa.telefono,
    client_id: client_id,
  }
}

export const assembleFullCertificate = (certificateForm, id_client, id_app) => {
  return {
    ...certificateForm,
    solicitud_id: id_app,
    client_id: id_client,
  }
}
