export const APPS_COMBOS_IDS = [
  'estados_solicitud_instalador',
  'estados_solicitud_instalador_filtro',
  'tipo_mercado',
  'tipo_vivienda',
  'tipo_energia',
  'iri',
  'provincias',
  'ubicacion_caldera',
  'tipo_termostato',
  'ubicacion_caldera_unifamiliar',
  'tipo_armario_regulacion',
  'gama_caldera',
  'cod_zona_zeus',
  'productos_instalacion',
  'ubicacion_contador',
  'rangos_potencia',
  'rangos_consumo_todos',
  'propiedad_contador',
  'propiedad_irc_armario_regulacion',
  'caso_caldera_centralizada',
  'tarifa_aplicada',
  'tiempo_contacto_instalador',
  'origen_solicitud',

  // 'gama_calentador',
]
