import { Box, Checkbox, FormHelperText, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAjaxContext } from '../../../../core/ajax'
// import { useConsultContext } from '../../../consult/contexts'
import { SelectInput, TextInput, ThemeButton } from '../../../../ui'
import { CampaignPanel } from '../../../campaigns'
import { Alert } from '@material-ui/lab'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
//import ArrowCircleLeftOutlined from '@material-ui/icons/ArrowCircleLeftOutlined'

export const CertificateServices = ({
  classes,
  formState,
  combos,
  handleChange,
  showErrors,
  errors,
  viewCampanas = true,
}) => {
  const { formatMessage } = useIntl()
  const { get } = useAjaxContext()
  const servicesStrigified = formState['services']?.join(',')
  // const { setCurrentCampana, empresa } = useConsultContext()
  const [financiacion, setFinanciacion] = useState(false)
  const [campanas, setCampanas] = useState()
  const [campana, setCampana] = useState()
  const [errorIban, setErrorIban] = useState(true)
  const [applieCampana, setApplieCampana] = useState()
  const [maxIndex, setMaxIndex] = useState(0)
  const [index, setIndex] = useState(0)
  const [dontViewApply, setDontViewApply] = useState(
    !formState.origen ? false : formState.origen !== 'instalador' ? true : false
  )
  let origen = null
  formState.origen !== undefined
    ? (origen = formState.origen)
    : window.sessionStorage.getItem('origen') === 'undefined'
    ? (origen = 'instalador')
    : (origen = window.sessionStorage.getItem('origen'))

  const updateCampana = (financiacion, data) => {
    if (financiacion && data.campana_financiacion) {
      setCampana(data.campana_financiacion)
      handleChange({ target: { name: 'financiacion', value: true } })
      // setCurrentCampana(data.campana_financiacion) //Set current campana on context to create requiered array
    } else {
      setCampana(data?.campana)
      handleChange({ target: { name: 'financiacion', value: false } })
      // setCurrentCampana(data?.campana) //Set current campana on context to create requiered array
    }
  }
  const applyCampaign = (event) => {
    const { name, checked } = event.target
    handleChange({ target: { name: name, value: checked } })
    if (checked) {
      //Apply campaign
      if (formState['financiacion']) {
        handleChange({
          target: {
            name: 'camp1',
            value: formState['campanas'].campana_financiacion.codigo,
          },
        })
      } else {
        handleChange({
          target: {
            name: 'camp1',
            value: formState['campanas'] ? formState['campanas']?.campana?.codigo : null,
          },
        })
      }
    } else {
      //Reseteamos el "camp1" cada vez que desmarcamos el check para aplicar la campaña
      handleChange({
        target: {
          name: 'camp1',
          value: undefined,
        },
      })
    }
  }
  const updateFinanciacion = () => {
    applyCampaign({ target: { name: 'applyCampaign', checked: false } })
    handleChange({ target: { name: 'iban', value: '' } })
    setFinanciacion((financiacion) => {
      updateCampana(!financiacion, campanas[index])
      return !financiacion
    })
  }

  const isValidIban = (iban) => {
    const regex =
      /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/
    return regex.test(iban)
  }

  const updateIban = (event) => {
    if (!isValidIban(event.target.value)) {
      setErrorIban(true)
    } else {
      setErrorIban(false)
    }
    handleChange({ target: { name: 'iban', value: event.target.value } })
  }

  useEffect(() => {
    //Reseteamos el "camp1" cada vez que cambiamos el selector: "Servicio a instalar"
    //para evitar que se envíe si ya hemos seleccionado una campaña y después se cambian los servicios desde este selector
    handleChange({
      target: {
        name: 'camp1',
        value: undefined,
      },
    })

    handleChange({ target: { name: 'origen', value: origen } })
    if (!formState['cidi'] || !formState['services']) return
    const params = { empresa_ca_id: formState.empresa_ca_id, origen: origen }
    formState['services'].map((el) => (params[el] = true))
    get(`/campana/by_cidi/${formState['cidi']}`, { params }).then(({ data }) => {
      const dataCampanas = data.campanas?.filter((el) => el?.campana?.fechainicio  && new Date(el?.campana?.fechainicio) <= new Date(formState.fecha_creacion)) || []
      setMaxIndex(dataCampanas.length)
      applyCampaign({ target: { name: 'applyCampaign', checked: dontViewApply ? true : false } })
      setApplieCampana(data.applies_empresa)
      handleChange({ target: { name: 'applieCampana', value: data.applies_empresa } })
      setCampanas(dataCampanas[index])
      setFinanciacion(false)
      updateCampana(false, dataCampanas[index])
      dataCampanas.length <= 0
        ? handleChange({ target: { name: 'campanas', value: null } })
        : handleChange({ target: { name: 'campanas', value: dataCampanas[index] } })
    })
  }, [servicesStrigified, index]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleAdvance = () => {
    //Reseteamos el "camp1" cada vez nos movemos entre campañas
    //para evitar que se éste envíe si ya se ha seleccionado una campaña y después se cambia a otra campaña
    handleChange({
      target: {
        name: 'camp1',
        value: undefined,
      },
    })
    setIndex(index + 1)
  }

  const handleRetraso = () => {
    //Reseteamos el "camp1" cada vez nos movemos entre campañas
    //para evitar que se éste envíe si ya se ha seleccionado una campaña y después se cambia a otra campaña
    handleChange({
      target: {
        name: 'camp1',
        value: undefined,
      },
    })
    setIndex(index - 1)
  }

  return (
    <Box>
      <Typography variant="body1" color="primary" paragraph>
        {formatMessage({ id: 'pages.certificates.services.title' })}
      </Typography>
      <Box marginBottom={3}>
        <Box display="flex" alignItems="center" className={classes.container}>
          <Box display="flex" flexDirection="row">
            <Box display="flex" flexDirection="column">
              <SelectInput
                className={classes.input}
                required
                name="services"
                value={formState['services']}
                values={combos?.productos_instalacion?.data || []}
                onChange={handleChange}
                size="small"
                multiple={true}
              />
              {viewCampanas && campanas?.campana_financiacion ? (
                <Box display="flex" alignItems="center" mt={2}>
                  <Typography variant="body2">
                    {formatMessage({ id: 'pages.certificates.services.finance' })}
                  </Typography>
                  <Checkbox
                    color="primary"
                    size="small"
                    name="financiacion"
                    onClick={() => {
                      updateFinanciacion()
                    }}
                  />
                </Box>
              ) : null}
              {(!financiacion &&
                formState['applyCampaign'] &&
                campanas?.campana?.ingreso &&
                applieCampana) ||
              (dontViewApply &&
                !financiacion &&
                campanas?.campana?.ingreso &&
                applieCampana &&
                !formState.cod_solicitud_zeus) ? (
                <Box /*display="flex" flexDirection="column"*/ mt={2} mb={2}>
                  <Typography variant="body1" color="primary" paragraph>
                    {formatMessage({ id: 'pages.certificates.services.iban.title' })}
                  </Typography>
                  <TextInput
                    required={formState['applyCampaign'] && !formState['financiacion']}
                    name="iban"
                    inputProps={{
                      pattern:
                        '(?:(?:IT|SM)\\d{2}[A-Z]\\d{22}|CY\\d{2}[A-Z]\\d{23}|NL\\d{2}[A-Z]{4}\\d{10}|LV\\d{2}[A-Z]{4}\\d{13}|(?:BG|BH|GB|IE)\\d{2}[A-Z]{4}\\d{14}|GI\\d{2}[A-Z]{4}\\d{15}|RO\\d{2}[A-Z]{4}\\d{16}|KW\\d{2}[A-Z]{4}\\d{22}|MT\\d{2}[A-Z]{4}\\d{23}|NO\\d{13}|(?:DK|FI|GL|FO)\\d{16}|MK\\d{17}|(?:AT|EE|KZ|LU|XK)\\d{18}|(?:BA|HR|LI|CH|CR)\\d{19}|(?:GE|DE|LT|ME|RS)\\d{20}|IL\\d{21}|(?:AD|CZ|ES|MD|SA)\\d{22}|PT\\d{23}|(?:BE|IS)\\d{24}|(?:FR|MR|MC)\\d{25}|(?:AL|DO|LB|PL)\\d{26}|(?:AZ|HU)\\d{27}|(?:GR|MU)\\d{28})$',
                    }}
                    value={formState['iban']}
                    onChange={updateIban}
                  />
                  <FormHelperText error={(showErrors && errors['iban']) || errorIban}>
                    {errorIban &&
                      formatMessage({
                        id: 'pages.certificates.iban.error.text',
                      })}
                  </FormHelperText>
                  <Typography variant="body1" color="primary" paragraph>
                    {formatMessage({ id: 'pages.certificates.services.iban.owner.title' })}
                  </Typography>
                  <TextInput
                    required={
                      (formState['applyCampaign'] && !formState['financiacion']) ||
                      (dontViewApply && !financiacion && campanas?.campana?.ingreso)
                    }
                    name="titular_iban"
                    value={formState['titular_iban']}
                    onChange={handleChange}
                  />
                  <FormHelperText error={(showErrors && errors['ibanOwner']) || true}>
                    {errors['titular_iban'] &&
                      formatMessage({
                        id: 'pages.certificates.iban.owner.error.text',
                      })}
                  </FormHelperText>
                </Box>
              ) : null}
            </Box>
          </Box>
          {viewCampanas && campana && !formState.cod_solicitud_zeus ? (
            applieCampana ? (
              <Box className={classes.campaignBox}>
                {!dontViewApply ? (
                  !formState.applyCampaign ? (
                    <Box marginBottom="1em">
                      <Alert severity="warning">
                        {formatMessage({ id: 'pages.certificates.services.apply.campaing.info' })}
                      </Alert>
                    </Box>
                  ) : null
                ) : null}
                <CampaignPanel
                  formState={campana}
                  isFinanceCampaign={financiacion}
                  index={index}
                  setIndex={setIndex}
                  maxIndex={maxIndex}
                />
                {!dontViewApply && (
                  <Checkbox
                    name="applyCampaign"
                    checked={formState.applyCampaign ? formState.applyCampaign : false}
                    onChange={applyCampaign}
                  />
                )}
                {!dontViewApply &&
                  formatMessage({ id: 'pages.certificates.services.apply.campaing' })}

                <Box display="flex" justifyContent="center" height="100%">
                  <Box display="flex" marginRight="1em">
                    {index > 0 && <ArrowBackIosIcon onClick={handleRetraso} />}
                  </Box>
                  <Box display="flex">
                    <Typography>{`${index + 1}/${maxIndex}`}</Typography>
                  </Box>
                  <Box display="flex" marginLeft="0.9em">
                    {index < maxIndex - 1 && maxIndex > 1 && (
                      <ArrowForwardIosIcon onClick={handleAdvance} />
                    )}
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box ml={1} display="flex" justifyContent="flex-end">
                <Alert severity="warning">
                  {formatMessage({
                    id: 'pages.certificates.services.noapply.warning',
                  })}
                </Alert>
              </Box>
            )
          ) : null}
        </Box>
        <FormHelperText error={showErrors && errors['services']}>
          {showErrors && errors['services']
            ? formatMessage({ id: 'pages.certificates.services.error.text' })
            : null}
        </FormHelperText>
      </Box>
    </Box>
  )
}

export default CertificateServices
