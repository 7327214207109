import TABLET_IMG from '../../../resources/images/privateAreaNavigationPage/tablet.jpg'

export { TABLET_IMG }

export const privateAreaGuideArray = [
  {
    title: 'potencialData',
    description: 'potencialDataDesc',
    url: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2022/08/DATOS-DEL-POTENCIAL-Conocer-los-datos-de-un-nuevo-punto-de-suministro.pdf',
  },
  {
    title: 'potencialDataNoCups',
    description: 'potencialDataNoCupsDesc',
    url: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2022/08/DATOS-DEL-POTENCIAL-No-existe-la-direccion-o-CUPS-en-el-buscador-de-Area-Privada.pdf',
  },
  {
    title: 'applicationNoGas',
    description: 'applicationNoGasDesc',
    url: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2022/10/SOLICITUD-Tramitar-una-solicitud-de-conexion-SP-SH-PYME-1.pdf',
  },
  {
    title: 'applicationWithGasSV',
    description: 'applicationWithGasSVDesc',
    url: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/CREAR-SOLICITUD-PARA-FINCA-CON-GAS-SV-Julio-2021.pdf',
  },
  {
    title: 'certificates',
    description: 'certificatesDesc',
    url: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2022/08/CERTIFICADOS-Informar-los-certificados-de-instalacion.pdf',
  },
  {
    title: 'marketGas',
    description: 'marketGasDesc',
    url: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2022/08/MARKETGAS-Configurar-tarifas-en-Marketgas.pdf',
  },
  /*   {
    title: 'financing',
    description: 'financingDesc',
    url: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2022/08/FINANCIACION-Configurar-mi-perfil-para-aplicar-la-financiacion.pdf',
  }, */
  {
    title: 'campaigns',
    description: 'campaignsDesc',
    url: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2022/08/CAMPANAS-Informar-campanas-y-ofertas.pdf',
  },
  {
    title: 'op',
    description: 'opDesc',
    url: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2022/08/OFERTA-PUBLICA-Manual-de-registro-para-la-Oferta-Pub.pdf',
  },
  {
    title: 'petitions',
    description: 'petitionsDesc',
    url: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2022/08/PETICIONES-Ver-el-detalle-de-mis-peticiones.pdf',
  },
  {
    title: 'budget',
    description: 'budgetDesc',
    url: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2022/08/PRESUPUESTO-Ver-el-presupuesto-de-extension-de-red-y-1.pdf',
  },
  {
    title: 'documents',
    description: 'documentsDesc',
    url: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/DOCUMENTOS-COMO-COMPLEMENTAR-DOCUMENTOS-A-LA-PETICION-1.pdf',
  },
  {
    title: 'states',
    description: 'statesDesc',
    url: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2022/08/ESTADOS-Como-ver-el-estado-de-una-solicitud-certificado.pdf',
  },
  {
    title: 'users',
    description: 'usersDesc',
    url: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2022/08/USUARIOS-Anadir-y-eliminar-nuevos-colaboradores-a-mi-perfil.pdf',
  },
  {
    title: 'installer',
    description: 'installerDesc',
    url: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2022/08/INSTALADOR-Vincularse-y-desvincularse-a-un-gremio-para-que.pdf',
  },
  {
    title: 'guild',
    description: 'guildDesc',
    url: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2022/08/GREMIO-Vincular-y-desvincular-el-instalador-al-perfil-del-Gre.pdf',
  },
  {
    title: 'profile',
    description: 'profileDesc',
    url: ' https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2022/08/PERFIL-Recuperar-y-Cambiar-Contrasena-Editar-datos-de-Perfil.pdf',
  },
  {
    title: 'privateAreaRegistration',
    description: 'privateAreaRegistrationDesc',
    url: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2022/08/ALTA-AREA-PRIVADA-Darse-de-alta-en-Area-Privada-2.pdf',
  },
  {
    title: 'mobile',
    description: 'mobileDesc',
    url: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/07/M%C3%B3vil-Acceder-a-mi-%C3%81rea-Privada-desde-el-M%C3%B3vil.pdf',
  },
  {
    title: 'brand',
    description: 'brandDesc',
    url: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2022/09/USO-DE-LA-MARCA.pdf',
  },
  {
    title: 'ip',
    description: 'ipDesc',
    url: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2022/09/Inspecciones-realizadas-por-empresas-instaladoras.pdf',
  },
  {
    title: 'ariba',
    description: 'aribaDesc',
    url: 'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2023/11/Ariba-Guia-VAcercaF4-22_11_2023.pdf',
  },

]
