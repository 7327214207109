export const ROOT_CERTIFICATES_FORM = 'pages.certificates.form'
export const ROOT_CERTIFICATES_DEVICES = 'pages.certificates.devices'
export const ROOT_CERTIFICATES_DOCUMENTS = 'pages.certificates.documents'

const TEXT_AREA_LENGTH = 250

//Reminder: these configuration arrays prepare data structure, but the fields need to be structured on the constants below in this document
const DEVICE_TYPES = [
  'calefaccion',
  'calentador',
  'cocina',
  'encimera',
  'secadora',
  'aparato_extra_1',
]
const DEVICE_EXTS = ['_nombre', '_nuevos', '_transformar', '_marca', '_potencia']
const DOCUMENT_TYPES = [
  'AC',
  'AI',
  'AR',
  'CA',
  'CB',
  'CC',
  'CCEE',
  'CH',
  'CI',
  'CM',
  'CP',
  'CPI',
  'CR',
  'DN',
  'EC',
  'FC1',
  'FC2',
  'FC3',
  'IRG1',
  'IRG2',
  'IRG3',
  'SG',
  'SP',
]
const DOCUMENT_EXTS = ['id', 'nombre', 'extension']

export const CERTIFICATES_DATA_TRANSFORMATION = ({ application = {}, combos = {} }) => {
  const empresa = application.empresa_ca || {}
  const usuario = application.usuario || {}
  const provincias = {}
  if (combos.provincias && combos.provincias.data) {
    combos.provincias.data.forEach((provincia) => {
      provincias[parseInt(provincia.key)] = provincia.value
    })
  }
  const device_data = {}
  DEVICE_TYPES.forEach((device) => {
    DEVICE_EXTS.forEach((ext) => {
      if (application[device + ext]) device_data[device + ext] = application[device + ext]
    })
  })
  const document_data = {}
  DOCUMENT_TYPES.forEach((docu) => {
    if (application['d_' + docu]) {
      DOCUMENT_EXTS.forEach((ext) => {
        document_data['d_' + docu + '_' + ext] = application['d_' + docu][ext]
      })
    }
  })
  //Prepare data for the service field for IRG3 certificate
  const services = []
  if (application.calefaccion) services.push('calefaccion')
  if (application.cocina) services.push('cocina')
  if (application.agua_caliente) services.push('agua_caliente')

  return {
    fecha_certificado: null,
    gas_natural: true,
    //Data from application
    solicitud_id: application.id,
    cidi: application.cidi,
    presupuesto_id: application.presupuesto_exterior?.id,
    cod_solicitud_zeus: application.cod_solicitud_zeus,
    client_id: usuario.id,
    tipo_mercado: application.mercado,
    cups: application.cups,
    nombre_titular: usuario.nombre + ' ' + usuario.apellidos,
    tipo_documento_titular: usuario.tipo_identificador,
    numero_documento_titular: usuario.identificador,
    telefono_movil_visita: usuario.telefono,
    email_visita: usuario.email,
    codigo_financiacion: application.codigo_financiacion,
    wasFinanciacion: application.acepta_financiacion,
    wasSubvencion: application.campana?.descuento,
    wasTransferencia: application.campana?.ingreso,
    currentCampanaId: application.campana?.id ? application.campana.id : null,
    origen: application.tipo_solicitud,
    wasSubTrans: application.campana?.ingreso && application.campana?.descuento,
    // provincia_emplazamiento: provincias[parseInt(application.provincia_id)], // Este dato se sacaba del objeto que representa al combo de provincias en lugar de, directamente, la solicitud por algún motivo?
    provincia_emplazamiento: parseInt(application.provincia_id), // Cambio temporal (debido a la ausencia del valor 13, Ciudad Real) a la espera de solucionar las dudas.
    municipio_emplazamiento: application.municipio,
    via_emplazamiento: application.direccion,
    numero_emplazamiento: application.numero,
    portal_emplazamiento: application.bloque,
    escalera_emplazamiento: application.escalera,
    piso_emplazamiento: application.piso,
    puerta_emplazamiento: application.puerta,
    //Data from installator
    empresa: empresa.nombre,
    empresa_ca_id: empresa.id,
    numero_registro: null,
    telefono_movil_empresa: empresa.telefono,
    //Devices
    ...device_data,
    //Documents
    ...document_data,
    services: services,
    fecha_creacion: application.dg_ts_insert,
  }
}

export const CERTIFICATES_FORM_CONFIG = ({ combos, intl, ...commonProps }) => {
  const { formatMessage } = intl
  const txt = (extension) => formatMessage({ id: ROOT_CERTIFICATES_FORM + '.' + extension })

  return {
    //Header:
    FECHA_CERTIFICADO: {
      fromData: (data) => ({
        value: data['fecha_certificado']
      }),
      required: true,
      text_invalid: 'La fecha tiene que estar en formato dd/mm/AAAA',
      componentType: 'date',
      name: 'fecha_certificado',
      title: txt('header.fecha_certificado.title'),
      placeholder: txt('header.fecha_certificado.placeholder'),
      ...commonProps,
    },
    TIPO_MERCADO: {
      fromData: (data) => ({
        value: data['tipo_mercado'],
        values: combos['tipo_mercado'] ? combos['tipo_mercado'].data : [],
      }),
      required: true,
      text_invalid: 'Campo obligatorio',
      select: true,
      type: 'select',
      name: 'tipo_mercado',
      title: txt('header.tipo_mercado.title'),
      placeholder: txt('header.tipo_mercado.placeholder'),
      ...commonProps,
    },
    NUM_POLIZA: {
      fromData: (data) => ({
        value: data['numero_poliza'],
      }),
      name: 'numero_poliza',
      title: txt('header.numero_poliza.title'),
      placeholder: txt('header.numero_poliza.placeholder'),
      ...commonProps,
    },
    CODIGO_FINANCIACION: {
      fromData: (data) => ({
        value: data['codigo_financiacion'],
        hidden: !data.campanas?.['campana_financiacion'],
      }),
      name: 'codigo_financiacion',
      title: txt('header.codigo_financiacion.title'),
      text_invalid: 'El formato del código no es válido. Ejemplo: 202105880547041',
      ...commonProps,
    },
    CUPS: {
      fromData: (data) => ({
        value: data['cups'],
      }),
      name: 'cups',
      text_invalid: 'Campo obligatorio',
      title: txt('header.cups.title'),
      placeholder: txt('header.cups.placeholder'),
      ...commonProps,
    },
    //Empres)a
    EMPRESA: {
      fromData: (data) => ({
        value: data['empresa'],
      }),
      type: 'text',
      componentType: 'read_only',
      name: 'empresa',
      title: txt('empresa.empresa.title'),
      ...commonProps,
    },
    NUM_REGISTRO: {
      fromData: (data) => ({
        value: data['numero_registro'],
      }),
      type: 'text',
      componentType: 'read_only',
      name: 'numero_registro',
      title: txt('empresa.numero_registro.title'),
      ...commonProps,
    },
    TELF_EMPRESA: {
      fromData: (data) => ({
        value: data['telefono_movil_empresa'],
      }),
      type: 'text',
      componentType: 'read_only',
      name: 'telefono_movil_empresa',
      title: txt('empresa.telefono_movil_empresa.title'),
      ...commonProps,
    },
    //Client
    NOMBRE_TITULAR: {
      fromData: (data) => ({
        value: data['nombre_titular'],
      }),
      type: 'text',
      componentType: 'read_only',
      name: 'nombre_titular',
      title: txt('client.nombre_titular.title'),
      ...commonProps,
    },
    DOCUMENTO_TITULAR: {
      fromData: (data) => ({
        value: data['numero_documento_titular'],
        title:
          combos['tipo_documento'] && combos['tipo_documento'][data['tipo_documento_titular']]
            ? combos['tipo_documento'][data['tipo_documento_titular']]
            : txt('client.documento.title'),
      }),
      type: 'text',
      componentType: 'read_only',
      name: 'numero_documento_titular',
      ...commonProps,
    },
    TELF_MOVIL_VISITA: {
      fromData: (data) => ({
        value: data['telefono_movil_visita'],
      }),
      type: 'text',
      componentType: 'read_only',
      name: 'telefono_movil_visita',
      title: txt('client.telefono_movil_visita.title'),
      ...commonProps,
    },
    EMAIL_VISITA: {
      fromData: (data) => ({
        value: data['email_visita'],
      }),
      type: 'text',
      componentType: 'read_only',
      name: 'email_visita',
      title: txt('client.email_visita.title'),
      ...commonProps,
    },
    ADDRESS: {
      fromData: (data) => ({
        value:
          `${data['via_emplazamiento']} ${data['numero_emplazamiento']}` +
          `${data['portal_emplazamiento'] ? '-' + data['portal_emplazamiento'] : ''} ` +
          `${data['escalera_emplazamiento'] ? '-' + data['escalera_emplazamiento'] : ''} ` +
          `${data['piso_emplazamiento']} ${data['puerta_emplazamiento']}`,
      }),
      type: 'text',
      componentType: 'read_only',
      name: 'address',
      title: txt('client.address_visita.title'),
      ...commonProps,
    },
    OBSERVACIONES: {
      fromData: (data) => ({
        value: data['observaciones'],
      }),
      multiline: true,
      rows: 3,
      inputProps: { maxLength: TEXT_AREA_LENGTH },
      name: 'observaciones',
      title: txt('observaciones.title'),
      placeholder: txt('observaciones.placeholder'),
      ...commonProps,
    },
  }
}

export const CERTIFICATES_DEVICE_CONFIG = ({ intl }) => {
  const { formatMessage } = intl
  const txt = (extension) => formatMessage({ id: ROOT_CERTIFICATES_DEVICES + '.' + extension })
  return {
    CALEFACCION: {
      id: 'calefaccion',
      label: txt('calefaccion.title'),
    },
    CALENTADOR: {
      id: 'calentador',
      label: txt('calentador.title'),
    },
    COCINA: {
      id: 'cocina',
      label: txt('cocina.title'),
    },
    ENCIMERA: {
      id: 'encimera',
      label: txt('encimera.title'),
    },
    SECADORA: {
      id: 'secadora',
      label: txt('secadora.title'),
    },
    EXTRA: {
      id: 'aparato_extra_1',
    },
  }
}

const GRID_ITEM_PROPS = {
  item: true,
  xs: 12,
  sm: 6,
  md: 4,
  lg: 3,
}
const GRID_ITEM_QUADRUPLET = {
  item: true,
  xs: 12,
  sm: 6,
  md: 3,
}
const GRID_ITEM_TRIPLET = {
  item: true,
  xs: 12,
  md: 4,
}

const CERTIFICATES_FORM_HEADER_STRUCTURE = [
  [
    { ref: 'FECHA_CERTIFICADO', conf: GRID_ITEM_PROPS },
    { ref: 'TIPO_MERCADO', conf: GRID_ITEM_PROPS },
    { ref: 'NUM_POLIZA', conf: GRID_ITEM_PROPS },
    { ref: 'CUPS', conf: GRID_ITEM_PROPS },
  ],
]
const CERTIFICATES_FORM_HEADER_STRUCTURE_IRG3 = [
  [
    { ref: 'FECHA_CERTIFICADO', conf: GRID_ITEM_PROPS },
    { ref: 'TIPO_MERCADO', conf: GRID_ITEM_PROPS },
    { ref: 'NUM_POLIZA', conf: GRID_ITEM_PROPS },
    { ref: 'CUPS', conf: GRID_ITEM_PROPS },
    { ref: 'CODIGO_FINANCIACION', conf: GRID_ITEM_PROPS },
  ],
]
const CERTIFICATES_FORM_EMPRESA_STRUCTURE = [
  [
    { ref: 'EMPRESA', conf: GRID_ITEM_TRIPLET },
    { ref: 'NUM_REGISTRO', conf: GRID_ITEM_TRIPLET },
    { ref: 'TELF_EMPRESA', conf: GRID_ITEM_TRIPLET },
  ],
]
const CERTIFICATES_FORM_CLIENT_STRUCTURE = [
  [
    { ref: 'NOMBRE_TITULAR', conf: GRID_ITEM_QUADRUPLET },
    { ref: 'DOCUMENTO_TITULAR', conf: GRID_ITEM_QUADRUPLET },
    { ref: 'ADDRESS', conf: GRID_ITEM_QUADRUPLET },
    { ref: 'EMAIL_VISITA', conf: GRID_ITEM_QUADRUPLET },
  ],
  [{ ref: 'TELF_MOVIL_VISITA', conf: GRID_ITEM_PROPS }],
]

const CERTIFICATES_FORM_UPPER_PART = (txt) => [
  {
    foldable: false,
    items: CERTIFICATES_FORM_HEADER_STRUCTURE,
  },
  {
    title: txt('empresa.title'),
    items: CERTIFICATES_FORM_EMPRESA_STRUCTURE,
  },
  {
    title: txt('client.title'),
    items: CERTIFICATES_FORM_CLIENT_STRUCTURE,
  },
]
const CERTIFICATES_FORM_UPPER_PART_IRG3 = (txt) => [
  {
    foldable: false,
    items: CERTIFICATES_FORM_HEADER_STRUCTURE_IRG3,
  },
  {
    title: txt('empresa.title'),
    items: CERTIFICATES_FORM_EMPRESA_STRUCTURE,
  },
  {
    title: txt('client.title'),
    items: CERTIFICATES_FORM_CLIENT_STRUCTURE,
  },
]
export const CERTIFICATEyt_ACTION_TYPES = {
  SET_LOADING: 'SIR_LOAD',
  SET_FAILURE: 'SIR_FAILURE',
  SET_COMPANY: 'SET_COMPANY',
  CHANGE_FORM_STATE: 'CHANGE_FORM_STATE',
  SET_FORM_STATE_SAVED: 'SET_FORM_STATE_SAVED',
  SET_SAVING: 'SET_SAVING',
  COMPANY_SET_CHECK_OP: 'COMPANY_SET_CHECK_OP',
}

export const CERTIFICATE_INITIAL_STATE = {
  wasFinanciacion: false,
  wasTransferencia: false,
  wasSubvencion: false,
  isFinanciacion: false,
  isTransferencia: false,
  isSubvencion: false,
  currentCampanaId: 0,
  newCampanaId: 0,
}

export const CERTIFICATES_FORM_STRUCTURE = ({ application, intl, formState }) => {
  if (!formState.CCEE) {
    delete formState.d_CCEE_extension
    delete formState.d_CCEE_id
    delete formState.d_CCEE_nombre
  }
  const { formatMessage } = intl
  const txt = (extension) => formatMessage({ id: ROOT_CERTIFICATES_FORM + '.' + extension })

  return {
    '01': {
      hidden:
        application.mercado === 'SV' ||
        (application.pasar_contratable && application.estado === '05'),
      doc_in_app: 'certificado_IRG1',
      label: txt('irg1'),
      structure: [...CERTIFICATES_FORM_UPPER_PART(txt)],
      documents: ['IRG1', 'SP', 'FC1'],
      validation: [
        {
          field: 'd_IRG1_id',
          custom_validation: 'nonZero',
          // text_invalid: 'Este documento es obligatorio',
        },

        {
          field: 'cups',
          custom_validation: 'required',
        },
        {
          field: 'tipo_mercado',
          custom_validation: 'required',
        },
      ],
      disclaimer: txt('check.irg'),
      endpoint: '/certificado_IRG1',
    },
    '02': {
      hidden: false,
      tooltip: txt('irg2.tooltip'),
      doc_in_app: 'certificado_IRG2',
      label: txt('irg2'),
      structure: [...CERTIFICATES_FORM_UPPER_PART(txt)],
      documents: ['IRG2', 'AI', 'SP', 'AR', 'FC1'],
      validation: [
        {
          field: 'd_IRG2_id',
          custom_validation: 'nonZero',
          // text_invalid: 'Este documento es obligatorio',
        },
        {
          field: 'tipo_mercado',
          custom_validation: 'required',
        },
      ],
      disclaimer: txt('check.irg'),
      endpoint: '/certificado_IRG2',
    },
    '03': {
      hidden: application.pasar_contratable && application.estado === '05',
      doc_in_app: 'certificado_IRG3',
      label: txt('irg3'),
      structure: [...CERTIFICATES_FORM_UPPER_PART_IRG3(txt)],
      documents: ['IRG3', 'DN', 'AI', 'SP', 'EC', 'AC', 'CA', 'CCEE', 'CPI', 'CB', 'FC1'],
      devices: ['CALEFACCION', 'CALENTADOR', 'COCINA', 'ENCIMERA', 'SECADORA', 'EXTRA'],
      services: true,
      validation:
        formState?.campanas?.campana?.descuento &&
        !formState?.campanas?.campana?.ingreso &&
        formState.applieCampana &&
        formState.applyCampaign
          ? [
              {
                field: 'd_IRG3_id',
                custom_validation: 'nonZero',
                // text_invalid: 'Este documento es obligatorio',
              },
              {
                field: 'd_CPI_id',
                custom_validation: 'nonZero',
                // text_invalid: 'Este documento es obligatorio',
              },
              {
                field: 'd_CCEE_id',
                custom_validation: 'nonZero',
                // text_invalid: 'Este documento es obligatorio',
              },
              {
                field: 'd_DN_id',
                custom_validation: 'nonZero',
                // text_invalid: 'Este documento es obligatorio',
              },
              {
                field: 'cups',
                custom_validation: 'required',
              },
              {
                field: 'tipo_mercado',
                custom_validation: 'required',
              },
              {
                field: 'services',
                custom_validation: 'notEmpty',
              },
              {
                field: 'codigo_financiacion',
                custom_validation: 'regex',
                aux: /^20[23][0-9](1[0-2]|0[1-9])[0-9]{9}$/,
              },
            ]
          : !formState?.campanas?.campana?.descuento &&
            formState?.campanas?.campana?.ingreso &&
            formState.applieCampana &&
            formState.applyCampaign
          ? [
              {
                field: 'd_IRG3_id',
                custom_validation: 'nonZero',
                // text_invalid: 'Este documento es obligatorio',
              },
              {
                field: 'd_CCEE_id',
                custom_validation: 'nonZero',
                // text_invalid: 'Este documento es obligatorio',
              },
              {
                field: 'd_DN_id',
                custom_validation: 'nonZero',
                // text_invalid: 'Este documento es obligatorio',
              },
              {
                field: 'd_CB_id',
                custom_validation: 'nonZero',
                // text_invalid: 'Este documento es obligatorio',
              },
              {
                field: 'cups',
                custom_validation: 'required',
              },
              {
                field: 'tipo_mercado',
                custom_validation: 'required',
              },
              {
                field: 'services',
                custom_validation: 'notEmpty',
              },
              {
                field: 'codigo_financiacion',
                custom_validation: 'regex',
                aux: /^20[23][0-9](1[0-2]|0[1-9])[0-9]{9}$/,
              },
            ]
          : formState?.campanas?.campana?.descuento &&
            formState.campanas?.campana?.ingreso &&
            formState.applieCampana &&
            formState.applyCampaign
          ? [
              {
                field: 'd_IRG3_id',
                custom_validation: 'nonZero',
                // text_invalid: 'Este documento es obligatorio',
              },
              {
                field: 'cups',
                custom_validation: 'required',
              },
              {
                field: 'd_CPI_id',
                custom_validation: 'nonZero',
                // text_invalid: 'Este documento es obligatorio',
              },
              {
                field: 'd_CCEE_id',
                custom_validation: 'nonZero',
                // text_invalid: 'Este documento es obligatorio',
              },
              {
                field: 'd_DN_id',
                custom_validation: 'nonZero',
                // text_invalid: 'Este documento es obligatorio',
              },
              {
                field: 'd_CB_id',
                custom_validation: 'nonZero',
                // text_invalid: 'Este documento es obligatorio',
              },
              {
                field: 'tipo_mercado',
                custom_validation: 'required',
              },
              {
                field: 'services',
                custom_validation: 'notEmpty',
              },
              {
                field: 'codigo_financiacion',
                custom_validation: 'regex',
                aux: /^20[23][0-9](1[0-2]|0[1-9])[0-9]{9}$/,
              },
            ]
          : [
              {
                field: 'd_IRG3_id',
                custom_validation: 'nonZero',
                // text_invalid: 'Este documento es obligatorio',
              },
              {
                field: 'cups',
                custom_validation: 'required',
              },
              {
                field: 'tipo_mercado',
                custom_validation: 'required',
              },
              {
                field: 'services',
                custom_validation: 'notEmpty',
              },
              {
                field: 'codigo_financiacion',
                custom_validation: 'regex',
                aux: /^20[23][0-9](1[0-2]|0[1-9])[0-9]{9}$/,
              },
            ],
      disclaimer: txt('check.irg'),
      endpoint: '/certificado_IRG3',
    },
    // '04': {
    //   label: txt('cm'),
    //   structure: [...CERTIFICATES_FORM_UPPER_PART(txt)],
    //   documents: ['CM', 'FC1'],
    //   validation: [
    //     {
    //       field: 'd_CM_id',
    //       custom_validation: 'nonZero',
    //     },
    //     {
    //       field: 'cups',
    //       custom_validation: 'required',
    //     },
    //     {
    //       field: 'tipo_mercado',
    //       custom_validation: 'required',
    //     },
    //   ],
    //   disclaimer: txt('check.cm'),
    //   endpoint: '/certificado_modificacion',
    // },
  }
}

const GRID_ITEM_MID = {
  item: true,
  xs: 12,
  sm: 6,
}

export const NEWITEMS_CONFIG = () => ({
  items: [
    [
      { ref: 'CODIGOIBAN', conf: GRID_ITEM_MID },
      { ref: 'TITULAR', conf: GRID_ITEM_MID },
      { ref: 'CODIGOFINANCIACION', conf: GRID_ITEM_MID },
    ],
  ],
})

export const DOCUMENT_ATTACHMENT_CONFIG = {
  CB: {
    id: 'd_CB_id',
    model: 'd_CB',
    type: 'CB',
    field: 'CB',
    label: 'CB.title',
    accept: ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'],
    maxSize: 10000000,
    disabled: true,
  },
  CPI: {
    id: 'd_CPI_id',
    model: 'd_CPI',
    type: 'CPI',
    field: 'CPI',
    label: 'cpi.title',
    accept: ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'],
    maxSize: 10000000,
    disabled: true,
  },
  CCEE: {
    id: 'd_CCEE_id',
    model: 'd_CCEE',
    type: 'CCEE',
    field: 'CCEE',
    label: 'ccee.title',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxSize: 10000000,
    disabled: true,
  },
}

export const CERTIFICATES_NEW_CAMPAING_ITEMS = ({ combos, intl, ...commonProps }) => {
  const { formatMessage } = intl
  const txt = (extension) => formatMessage({ id: ROOT_CERTIFICATES_FORM + '.' + extension })

  return {
    //Header:
    CODIGOIBAN: {
      fromData: (data) => ({
        value: data['iban'],
        hidden:
          (data.campanas.campana.ingreso && data.wasTransferencia && data.wasSubvencion) ||
          (data.campanas.campana.financiacion && data.wasTransferencia) ||
          (data.campanas.campana.descuento &&
            data.wasSubvencion &&
            !data.campanas.campana.ingreso) ||
          (data.financiacion && data.wasSubvencion) ||
          (data.wasFinanciacion &&
            data.campanas.campana.descuento &&
            !data.campanas.campana.ingreso) ||
          (data.campanas.campana.descuento &&
            !data.campanas.campana.ingreso &&
            data.wasTransferencia &&
            !data.wasFinanciacion),
        required: data.wasSubvencion && data.campanas.campana.ingreso,
      }),
      name: 'iban',
      inputProps: {
        pattern:
          '(?:(?:IT|SM)\\d{2}[A-Z]\\d{22}|CY\\d{2}[A-Z]\\d{23}|NL\\d{2}[A-Z]{4}\\d{10}|LV\\d{2}[A-Z]{4}\\d{13}|(?:BG|BH|GB|IE)\\d{2}[A-Z]{4}\\d{14}|GI\\d{2}[A-Z]{4}\\d{15}|RO\\d{2}[A-Z]{4}\\d{16}|KW\\d{2}[A-Z]{4}\\d{22}|MT\\d{2}[A-Z]{4}\\d{23}|NO\\d{13}|(?:DK|FI|GL|FO)\\d{16}|MK\\d{17}|(?:AT|EE|KZ|LU|XK)\\d{18}|(?:BA|HR|LI|CH|CR)\\d{19}|(?:GE|DE|LT|ME|RS)\\d{20}|IL\\d{21}|(?:AD|CZ|ES|MD|SA)\\d{22}|PT\\d{23}|(?:BE|IS)\\d{24}|(?:FR|MR|MC)\\d{25}|(?:AL|DO|LB|PL)\\d{26}|(?:AZ|HU)\\d{27}|(?:GR|MU)\\d{28})$',
      },
      placeholder: 'Ej. ES1234567891234567891234',
      required: true,
      fullWidth: true,
      title: txt('codigoIban.label'),
      componentType: 'text',
      ...commonProps,
    },
    TITULAR: {
      fromData: (data) => ({
        value: data['titular_iban'],
        hidden:
          (data.campanas.campana.ingreso && data.wasTransferencia && data.wasSubvencion) ||
          (data.campanas.campana.financiacion && data.wasTransferencia) ||
          (data.campanas.campana.descuento &&
            data.wasSubvencion &&
            !data.campanas.campana.ingreso) ||
          (data.financiacion && data.wasSubvencion) ||
          (data.wasFinanciacion &&
            data.campanas.campana.descuento &&
            !data.campanas.campana.ingreso) ||
          (data.campanas.campana.descuento &&
            !data.campanas.campana.ingreso &&
            data.wasTransferencia &&
            !data.wasFinanciacion),
        required: data.wasSubvencion && data.campanas.campana.ingreso,
      }),
      name: 'titular_iban',
      title: txt('titular.label'),
      required: true,
      ...commonProps,
    },
    CODIGOFINANCIACION: {
      fromData: (data) => ({
        value: data['codigo_financiacion'],
        hidden:
          (data.campanas.campana.ingreso && data.wasTransferencia && data.wasSubvencion) ||
          (data.wasTransferencia &&
            data.campanas.campana.ingreso &&
            data.campanas.campana.descuento) ||
          (data.wasSubvencion && data.campanas.campana.ingreso) ||
          (data.campanas.campana.descuento && data.wasSubvencion) ||
          (data.wasFinanciacion && data.campanas.campana.descuento) ||
          (data.wasFinanciacion && data.campanas.campana.ingreso) ||
          (data.campanas.campana.descuento &&
            !data.campanas.campana.ingreso &&
            data.wasTransferencia &&
            !data.wasFinanciacion),
      }),
      required: true,
      name: 'codigo_financiacion',
      placeholder: 'Ej. 202105880547041',
      inputProps: {
        type: 'codigo_financiacion',
        pattern: '20[23][0-9](1[0-2]|0[1-9])[0-9]{9}$',
      },
      title: txt('header.codigo_financiacion.title'),
      text_invalid: 'El formato del código no es válido. Ejemplo: 202105880547041',
      ...commonProps,
    },
  }
}
